// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bw_k4";
export var caseStudyBackground__lineColor = "bw_kW";
export var caseStudyHead = "bw_k6";
export var caseStudyHead__imageWrapper = "bw_kT";
export var caseStudyProjectsSection = "bw_k5";
export var caseStudyQuote__bgLight = "bw_k2";
export var caseStudyQuote__bgRing = "bw_k1";
export var caseStudyVideoReview = "bw_l1";
export var caseStudyVideoReview__bgRing = "bw_l2";
export var caseStudyVideo__ring = "bw_k8";
export var caseStudy__bgDark = "bw_kS";
export var caseStudy__bgLight = "bw_kR";
export var caseStudy__bgLightReverse = "bw_l0";